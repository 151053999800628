import React from "react";

/**
 * Inputs the button text into the Input field.
 * Requiers input with id .
 * @returns inputs text into input field
 */
export const CommentTextShortcuts = ({ commentId }) => {
  const handleClick = (e) => {
    let input = document.getElementById(commentId);

    if (input.value.length > 0) {
      // This will work by calling the native setter bypassing Reacts incorrect value change check
      Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        "value"
      ).set.call(input, input.value + " " + e.target.innerText);
    } else {
      Object.getOwnPropertyDescriptor(
        window.HTMLTextAreaElement.prototype,
        "value"
      ).set.call(input, e.target.innerText);
    }

    // This will trigger a new render wor the component
    input.dispatchEvent(new Event("change", { bubbles: true }));
  };

  return (
    <div className="shortcuts field flex-column mb-2">
      <label className="relative mb-1">Shortcuts:</label>
      <button type="button" onClick={(e) => handleClick(e)}>
        Ringt.
      </button>
      <button type="button" onClick={(e) => handleClick(e)}>
        Mailat.
      </button>
    </div>
  );
};
