import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import { fetchES, resetState } from "../redux/actions";
import { fetchMembers } from "../redux/members/actions";
import { fetchTags } from "../redux/tags/actions";
import { smallScreen } from "./tools";
import LogoutButton from "./logoutButton";

const Navigation = ({
  refreshMembers,
  refreshTags,
  refreshES,
  resetState,
  fetchedMembersTime,
  fetchedESTime,
  msalContext,
}) => {
  const [lastUpdated, setLastUpdated] = useState("Checking..");

  const openDrawer = () => {
    const drawer = document.getElementById("drawer");
    drawer.style.display = "block";
    drawer.style.animation = "0.5s slidein ";
  };
  const closeDrawer = () => {
    const drawer = document.getElementById("drawer");
    drawer.style.animation = "0.5s slideout";
    setTimeout(function () {
      drawer.style.display = "none";
    }, 400);
  };

  const refresh = () => {
    refreshMembers();
    refreshTags();
    refreshES();
  };

  useEffect(() => {
    const dateES = new Date(fetchedESTime);
    const dateChimp = new Date(fetchedMembersTime);
    const date = dateES > dateChimp ? dateES : dateChimp;
    const minutes = (date.getMinutes() < 10 ? "0" : "") + date.getMinutes();
    setLastUpdated(date.getHours() + ":" + minutes);
  }, [fetchedMembersTime, fetchedESTime]);

  if (smallScreen()) {
    return (
      <nav>
        <button onClick={() => openDrawer()} className="hamburger">
          <span></span>
          <span></span>
          <span></span>
        </button>
        <div id="drawer">
          <button className="cross" onClick={() => closeDrawer()}>
            X
          </button>
          <ul>
            <li>
              <NavLink to="/mailchimp" onClick={() => closeDrawer()}>
                <button>Mailchimp</button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/mailchimp/add-member" onClick={() => closeDrawer()}>
                <button>Add member</button>
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <NavLink to="/es" onClick={() => closeDrawer()}>
                <button>ES</button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/es/makers" onClick={() => closeDrawer()}>
                <button>Makers</button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/es/categories" onClick={() => closeDrawer()}>
                <button>Categories</button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/es/companies" onClick={() => closeDrawer()}>
                <button>Companies</button>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/es/companies/add-company"
                onClick={() => closeDrawer()}
              >
                <button>Add Company</button>
              </NavLink>
            </li>
            <li>
              <NavLink to="/es/machines" onClick={() => closeDrawer()}>
                <button>Machines</button>
              </NavLink>
            </li>
            <li>
              <NavLink
                to="/es/machines/add-machine"
                onClick={() => closeDrawer()}
              >
                <button>Add Machine</button>
              </NavLink>
            </li>
          </ul>
          <ul>
            <li>
              <button
                onClick={() => {
                  refresh();
                  closeDrawer();
                }}
              >
                Refresh | {lastUpdated}
              </button>
            </li>
            <li>
              <LogoutButton resetState={resetState} />
            </li>
          </ul>
        </div>
      </nav>
    );
  } else {
    return (
      <nav>
        <NavLink to="/mailchimp">
          <button>Mailchimp</button>
        </NavLink>
        <NavLink to="/mailchimp/add-member">
          <button>Add member</button>
        </NavLink>
        <div className="gap"></div>

        <NavLink to="/es/makers">
          <button>Makers</button>
        </NavLink>
        <NavLink to="/es/categories">
          <button>Categories</button>
        </NavLink>
        <div className="small-gap"></div>
        <NavLink to="/es/companies">
          <button>Companies</button>
        </NavLink>
        <NavLink to="/es/companies/add-company">
          <button>Add Company</button>
        </NavLink>
        <div className="small-gap"></div>
        <NavLink to="/es/machines">
          <button>Machines</button>
        </NavLink>
        <NavLink to="/es/machines/add-machine">
          <button>Add Machine</button>
        </NavLink>
        <LogoutButton resetState={resetState} />

        <button className="float-right" onClick={() => refresh()}>
          Refresh | {lastUpdated}
        </button>
      </nav>
    );
  }
};

const mapStateToProps = (state) => ({
  fetchedMembersTime: state.fetchedMembersTime,
  fetchedESTime: state.fetchedESTime,
});

const mapDispatchtoProps = (dispatch) => ({
  refreshMembers: () => dispatch(fetchMembers()),
  refreshTags: () => dispatch(fetchTags()),
  refreshES: () => dispatch(fetchES()),
  resetState: () => dispatch(resetState()),
});

export default connect(mapStateToProps, mapDispatchtoProps)(Navigation);
