import { getTags } from "../../api";

export const addTag = (tag) => ({
  type: "ADD_TAG",
  payload: tag,
});

export const addTags = (tag) => ({
  type: "ADD_TAGS",
  payload: tag,
});

export const fetchTags = () => {
  return (dispatch) => {
    console.log("Fetching tags");
    getTags().then((data) => dispatch(addTags(data.tags)));
  };
};
