export const displayTags = (tags) => {
    if (tags.length <= 1 && typeof tags[0] !== "undefined") {
      return tags[0].status !== "inactive" ? tags[0].name : null;
    } else {
      let newTagString = "";

      for (let i = 0; i < tags.length; i++) {
        if (tags[i].status !== "inactive") {
          if (
            typeof tags[i + 1] !== "undefined" &&
            tags[i + 1].status === "inactive"
          ) {
            newTagString = newTagString + tags[i].name;
          } else if (i === tags.length - 1) {
            newTagString = newTagString + tags[i].name;
          } else {
            newTagString = newTagString + tags[i].name + ", ";
          }
        }
      }

      return newTagString;
    }
  };
  