import { sendMessage } from "./components/sendMessage";

const axios = require("axios");

const domain = process.env.REACT_APP_ES_DOMAIN_NAME;
//axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
const token = `Bearer ${process.env.REACT_APP_ES_TOKEN}`;
const noLimit = "?pagination[1]=1&pagination[pageSize]=100000";

const getEsToken = async () => {
  console.log("authorizing smt es");
  return await axios
    .post(`${domain}/auth/local`, {
      identifier: process.env.REACT_APP_ES_USERNAME,
      password: process.env.REACT_APP_ES_PASSWORD,
    })
    .then((response) => `Bearer ${response.data.jwt}`) //`Bearer ${response.data.jwt}`
    .catch((error) => displayError(error));
};

const getSalesmen = async () => {
  //workforce is plural
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/workforce${noLimit}`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

const getContactActivities = async () => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/contact-activities${noLimit}`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

const getMakers = async () => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/makers${noLimit}`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

const getCategories = async () => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/categories${noLimit}`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

const getCompanies = async () => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/companies${noLimit}`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

/**
 * Get companies added after given lastAddedId
 * @param {*} token
 * @param {*} lastAddedId
 * @returns
 */
const getLatestCompanies = async (lastAddedId) => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/companies?filters[id][$gt]=${lastAddedId}&pagination[pageSize]=100000&pagination[page]=1`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

const getCountries = async () => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/countries${noLimit}`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

const getMachines = async () => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/machines${noLimit}`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

/**
 * Get machines added after given lastAddedId
 * @param {*} token
 * @param {*} lastAddedId
 * @returns
 */
const getLatestMachines = async (lastAddedId) => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/machines?filters[id][$gt]=${lastAddedId}&pagination[pageSize]=100000&pagination[page]=1`;
  return await axios
    .get(url, config)
    .then((response) => response.data)
    .catch((error) => displayError(error));
};

/**
 * Get last updated entries after given lastUpdated date
 * @param {*} token
 * @param {*} lastUpdated
 * @returns
 */
const getLastUpdated = async (type, lastUpdated) => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/${type}?filters[updatedAt][$gt]=${lastUpdated}&pagination[pageSize]=100000&pagination[page]=1`;
  return await axios
    .get(url, config)
    .then((response) => response.data.data)
    .catch((error) => displayError(error));
};

const getTypeCount = async (type) => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/${type}?pagination[start]=0&pagination[limit]=10`;
  return await axios
    .get(url, config)
    .then((response) => response.data.meta.pagination.total)
    .catch((error) => displayError(error));
};

/**
 * Get last added content for chosen 'type' after given 'lastAddedId'
 * @param {*} type ex. machine/companies
 * @param {*} token
 * @param {*} lastAddedId
 * @returns
 */
const getLatest = async (type, lastAddedId) => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/${type}?filters[id][$gt]=${lastAddedId}&pagination[pageSize]=100000&pagination[page]=1`;
  return await axios
    .get(url, config)
    .then((response) => response.data)
    .catch((error) => displayError(error));
};

/**
 * Post new objects to ES
 * @param {string} type makers / companies / machines / categories
 * @param {object} data what to send
 * @param {string} token jwt token
 */
const postToES = async (type, data) => {
  let config = {
    headers: { Authorization: token },
  };
  //console.log(token);
  //console.log(data);
  const url = `${domain}/${type}`;

  return await axios
    .post(url, { data: { ...data } }, config)
    .then((response) => {
      displaySuccess(response.data, type);
      return response.data;
    })
    .catch((error) => {
      if (type === "machines") {
        const newId = parseInt(data.machine_id) + 1;
        data.machine_id = newId.toString();
        return axios
          .post(url, { data: { ...data } }, config)
          .then((response) => {
            displaySuccess(response.data, type);
            return response.data;
          })
          .catch((error) => displayError(error));
      } else {
        displayError(error);
      }
    });
};

/**
 * Put  objects to ES
 * @param {string} type makers / companies / machines / categories
 * @param {object} data what to send
 * @param {string} token jwt token
 */
const putToES = async (type, data) => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/${type}/${data.id}`;
  return await axios
    .put(url, { data: { ...data } }, config)
    .then((response) => {
      displaySuccess(response.data, type);
      return response.data;
    })
    .catch((error) => displayError(error));
};

/**
 * Delete objects in ES
 * @param {string} type makers / companies / machines / categories
 * @param {object} data what to send
 * @param {string} token jwt token
 */
const deleteToES = async (type, data) => {
  let config = {
    headers: { Authorization: token },
  };
  const url = `${domain}/${type}/${data.id}`;
  return await axios
    .delete(url, config)
    .then((response) => response.data)
    .catch((error) => displayError(error));
};

const massDeleteToES = async (type, data) => {
  for (let i = 0; i < data.length; i++) {
    deleteToES(type, data[i], token);
  }
};

const displayError = (error) => {
  console.log(error);
  sendMessage(
    `ES api error - status code: ${error.response.error.status}, error: ${error.response.error.name}, message: ${error.response.error.message} `,
    "fail"
  );
};

const displaySuccess = (data, type) => {
  //console.log(data);
  if (type === "machines") {
    sendMessage(
      `${data.data.attributes.Model} added/edited successfully with ES nr: ${data.data.attributes.machine_id}`,
      "success"
    );
  } else if (type === "companies") {
    sendMessage(
      `${data.data.attributes.company_name} added/edited successfully with ID nr: ${data.data.attributes.company_id}`,
      "success"
    );
  } else {
    sendMessage(`Added to ES successfully.`, "success");
  }
};

export {
  getEsToken,
  getSalesmen,
  getContactActivities,
  getMakers,
  getCategories,
  getCompanies,
  getLatestCompanies,
  getCountries,
  getMachines,
  getLatestMachines,
  getLastUpdated,
  getTypeCount,
  postToES,
  putToES,
  deleteToES,
  massDeleteToES,
  getLatest,
};
