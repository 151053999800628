import { flatState } from "../flatState";

export const contactActivities = (state = [], action) => {
  switch (action.type) {
    case "ADD_CONTACT_ACTIVITIES":
      return flatState(action.payload);
    case "ADD_CONTACT_ACTIVITY":
      console.log(action.payload.data);
      return [
        ...state,
        { id: action.payload.data.id, ...action.payload.data.attributes },
      ];
    case "UPDATE_CONTACT_ACTIVITIES":
      const payloadIds = new Set(action.payload.map((activity) => activity.id));
      const filteredState = state.filter((activity) => !payloadIds.has(activity.id)); //Filters duplicates
      const flatPayload = flatState(action.payload);
      const updatedState = [...filteredState, ...flatPayload]; //Add what  the filter removed
      return updatedState;
    case "EDIT_CONTACT_ACTIVITY":
      console.log(`${action.payload.id} ${action.payload.type} contact activity edited`);
      const newState = state.map((m) =>
        m.id === action.payload.id ? (m = { ...action.payload }) : m
      );
      return newState;
    case "DELETE_CONTACT_ACTIVITY":
      const stateWithoutRemovedActivity = state.filter(
        (m) => m.id !== action.payload.id
      );
      return stateWithoutRemovedActivity;
    default:
      return state;
  }
};
