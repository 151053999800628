import {
  getContactActivities,
  postToES,
  putToES,
  deleteToES,
  getLastUpdated,
} from "../../apiES";
import { sortByDate } from "../../components/tools";
import { countAndCompareType } from "../countAndCompareType";

const addActivities = (data) => ({
  type: "ADD_CONTACT_ACTIVITIES",
  payload: data,
});

const fetchAllActivities = () => {
  return (dispatch) => {
    getContactActivities().then((data) => {
      if (typeof data !== "undefined") {
        dispatch(addActivities(data));
      }
    });
  };
};

const updateActivity = (data) => ({
  type: "UPDATE_CONTACT_ACTIVITIES",
  payload: data,
});


const fetchUpdatedActivities = () => {
  return (dispatch, getState) => {
    const activities = [...getState().contactActivities];
    const latestUpdatedActivity = sortByDate([...activities], "updatedAt");
    getLastUpdated("contact-activities", latestUpdatedActivity[0].updatedAt).then((data) => {
      if (typeof data !== "undefined" && data.length !== 0) {
        dispatch(updateActivity(data));
      }
    });
  };
};

export const fetchContactActivities = () => {
  return async (dispatch, getState) => {
    const activitiesLength = getState().contactActivities.length;
    const inSyncWithApi = await countAndCompareType("contact-activities", activitiesLength);

    if (activitiesLength > 0 && inSyncWithApi) {
      dispatch(fetchUpdatedActivities());
    } else {
      dispatch(fetchAllActivities());
    }
  };
};

const addActivity = (data) => ({
  type: "ADD_CONTACT_ACTIVITY",
  payload: data,
});

export const postActivity = (data) => {
  return (dispatch) => {
    postToES("contact-activities", data)
      .then((resp) => dispatch(addActivity(resp)))
      .catch((error) => console.log(error));
  };
};

const editActivity = (data) => ({
  type: "EDIT_CONTACT_ACTIVITY",
  payload: data,
});

export const putActivity = (data) => {
  return (dispatch) => {
    putToES("contact-activities", data)
      .then(() => dispatch(editActivity(data)))
      .catch((error) => console.log(error));
  };
};

const deleteActivity = (data) => ({
  type: "DELETE_MAKER",
  payload: data,
});

export const removeActivity = (data) => {
  return (dispatch) => {
    deleteToES("contact-activities", data)
      .then(() => dispatch(deleteActivity(data)))
      .catch((err) => console.log(err));
  };
};
