import { connect } from "react-redux";
import { formatDateTime } from "../tools";

const DisplayActivity = ({ contactId, activities }) => {
  if (!activities) {
    return <p>Error loading activities</p>;
  }
  const contactActivities = activities.filter(
    (a) => a.contact_id === contactId
  );

  if (contactActivities.length === 0) {
    return <p>No activities available</p>;
  }

  const list = contactActivities.reverse().map((a, i) => (
    <tr key={i}>
      <td>{a.type}</td>
      <td>{a.sales_name}</td>
      <td className="narrow">{a.message}</td>
      <td>{formatDateTime(a.createdAt)}</td>
    </tr>
  ));
  return (
    <table className="list">
      <thead>
        <tr>
          <th>Type</th>
          <th>Who</th>
          <th className="narrow">Message</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>{list}</tbody>
    </table>
  );
};

const addStateToProps = (state) => ({
  activities: state.contactActivities,
});

export default connect(addStateToProps, null)(DisplayActivity);
