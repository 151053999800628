import React from "react";
import { shortenLongString } from "../tools";
import { Link } from "react-router-dom";

export const memberlistMobile = (fixedList) => (
  <tbody>
    {fixedList.map((m, i) => {
      return (
        <tr className="member" key={i} data-testid="member">
          <td>
            <table>
              <tbody>
                <tr>
                  <td className="company_name">
                    <strong data-testid="member_company_name">
                      {m.merge_fields.COMPANY}
                    </strong>
                  </td>
                  <td className="name">
                    Name: {m.merge_fields.FNAME + " " + m.merge_fields.LNAME}
                  </td>
                  {m.merge_fields.ADDRESS.addr1 &&
                  m.merge_fields.ADDRESS.country ? (
                    <>
                      <td className="address">
                        {m.merge_fields.ADDRESS.addr1}
                        {m.merge_fields.ADDRESS.addr2}
                        {m.merge_fields.ADDRESS.state}
                        {m.merge_fields.ADDRESS.zip}
                        {m.merge_fields.ADDRESS.city}
                      </td>
                      <td className="country">
                        {m.merge_fields.ADDRESS.country}
                      </td>
                    </>
                  ) : null}

                  <td className="seller">
                    Seller: {m.merge_fields.SALES_NAME}
                  </td>
                  <td className="comment">
                    Comment:{" "}
                    {shortenLongString(m.merge_fields.lastMessage, 100)}
                  </td>
                </tr>
              </tbody>
            </table>
            <table>
              <tbody>
                <tr>
                  <td className="memberCardButtons">
                    {m.merge_fields.PHONE ? (
                      <a href={"tel:" + m.merge_fields.PHONE}>
                        <button>Ring</button>
                      </a>
                    ) : (
                      <button className="inactive">Ring</button>
                    )}

                    <a
                      href={"mailto:" + m.email_address}
                      data-testid="member_email"
                    >
                      <button>Maila</button>
                    </a>
                    <Link to={`/mailchimp/edit-member?id=${m.id}`}>
                      <button>Edit</button>
                    </Link>
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </tr>
      );
    })}
  </tbody>
);
