import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { postAndgetMembers } from "../../redux/members/actions";
import { countries } from "../countries";
import { withRouter } from "react-router-dom";
import { emailExists, useQuery, sortByString } from "../tools";
import { postActivity } from "../../redux/contactActivity/actions";
import { useMsal } from "@azure/msal-react";

const AddMemberForm = ({
  postAndgetMembers,
  tags,
  members,
  history,
  salesmen,
  addActivity,
  companies,
}) => {
  const query = useQuery();
  const { accounts } = useMsal();
  let currentActiveMsUserId = accounts[0].localAccountId;
  let currentActiveMsUserName = accounts[0].name;

  let initMember = {
    email_address: "",
    status: "subscribed",
    street_address: "",
    street_address2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "US",
    FNAME: "",
    LNAME: "",
    PHONE: "",
    COMPANY: "",
    COMMENT: "",
    SALES_ID: "",
    SALES_NAME: "",
    CREATED_BY: "",
    S_ID_TWO: "",
    S_NAME_TWO: "",
    tags: [],
  };
  const [member, setMember] = useState(initMember);
  const [sortedSalesmen, setSortedSalesmen] = useState([]);
  const [sortedCompanies, setSortedCompanies] = useState([]);

  const sortedTags = tags.sort(function (a, b) {
    var nameA = a.name.toUpperCase();
    var nameB = b.name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "tags") {
      setMemberTag(value);
    } else if (name === "email_address") {
      emailExists(value, members, e.target);
      setMember({ ...member, [name]: value });
    } else {
      setMember({ ...member, [name]: value });
    }

    document.getElementById("tag_selector").value = 0;
  };

  const setMemberTag = (id) => {
    const tagToAdd = tags.find((t) => t.id === parseInt(id));
    for (let i = 0; i < member.tags.length; i++) {
      if (member.tags[i].id === tagToAdd.id) {
        return null;
      }
    }
    const newTagList = [...member.tags, tagToAdd];
    setMember({ ...member, tags: newTagList });
  };

  const removeMemberTag = (e, id) => {
    e.preventDefault();

    const newTagList = member.tags.filter((m) => m.id !== id);
    setMember({ ...member, tags: newTagList });
  };

  const displayMemberTags = () => (
    <div className="tag_row">
      {member.tags.map((t) => {
        return (
          <div key={t.id} className="tag">
            <span>{t.name}</span>
            <button href="#" onClick={(e) => removeMemberTag(e, t.id)}>
              X
            </button>
          </div>
        );
      })}
    </div>
  );

  const handleSubmit = async (e) => {
    e.preventDefault();

    let seller, sellerName, sellerTwo, sellerNameTwo;

    if (member.SALES_ID.length > 0) {
      seller = salesmen.find((s) => s.azure_object_id === member.SALES_ID); //Find salesmen name in ES
      sellerName = seller ? seller.full_name : "";
    }

    if (member.S_ID_TWO.length > 0) {
      sellerTwo = salesmen.find((s) => s.azure_object_id === member.S_ID_TWO); //Find salesmen name in ES
      sellerNameTwo = sellerTwo ? sellerTwo.full_name : "";
    }

    const tagsOnlyNames = member.tags.map((t) => t.name);
    const memberData = {
      email_address: member.email_address,
      status: member.status,
      merge_fields: {
        FNAME: member.FNAME,
        LNAME: member.LNAME,
        ADDRESS: {
          addr1: member.street_address.length > 0 ? member.street_address : ".",
          addr2:
            member.street_address2.length > 0 ? member.street_address2 : ".",
          city: member.city.length > 0 ? member.city : ".",
          state: member.state.length > 0 ? member.state : ".",
          zip: member.zipcode.length > 0 ? member.zipcode : ".",
          country: member.country,
        },
        PHONE: member.PHONE,
        COMPANY: member.COMPANY,
        COUNTRY2: member.country,
        COMMENT: member.COMMENT,
        SALES_ID: member.SALES_ID,
        SALES_NAME: sellerName || "",
        CREATED_BY: currentActiveMsUserName || "",
        S_ID_TWO: member.S_ID_TWO,
        S_NAME_TWO: sellerNameTwo || "",
      },
      tags: tagsOnlyNames,
    };

    await postAndgetMembers(memberData).then((new_contact_id) => {
      //console.log("add member response", new_contact_id);
      if (member.COMMENT.length > 0) {
        addActivity({
          contact_id: new_contact_id,
          message: member.COMMENT,
          sales_id: currentActiveMsUserId,
          sales_name: currentActiveMsUserName || "",
          type: "Comment",
        });
      }
    });

    history.push(`/mailchimp`);
  };

  useEffect(() => {
    //checks url for quick adding contacts from example Companies
    if (query.get("email") || query.get("company_name")) {
      const urlInfo = {
        email_address: query.get("email") ? query.get("email") : "",
        street_address: query.get("street") ? query.get("street") : "",
        city: query.get("city") ? query.get("city") : "",
        zipcode: query.get("postal_code") ? query.get("postal_code") : "",
        country: query.get("country_code") ? query.get("country_code") : "US",
        PHONE: query.get("tel1") ? query.get("tel1") : "",
        COMPANY: query.get("company_name") ? query.get("company_name") : "",
      };

      if (query.get("email")) {
        emailExists(
          query.get("email"),
          members,
          document.getElementById("email_address_field")
        );
      }

      for (const [key, value] of Object.entries(urlInfo)) {
        setMember((prev) => ({ ...prev, [key]: value }));
      }
    }
  }, [query, members]);

  useEffect(() => {
    const sortedSellers = sortByString(salesmen, "full_name");
    setSortedSalesmen(sortedSellers);
  }, [salesmen]);

  useEffect(() => {
    const sortedCompanies = sortByString(companies, "company_name");
    setSortedCompanies(sortedCompanies);
  }, [companies]);

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add member</h3>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="COMPANY"
              value={member.COMPANY}
              onChange={(e) => handleChange(e)}
            />
            <label>Company name</label>
          </div>
          <div className="field">
            <select
              name="COMPANY"
              value={member.COMPANY}
              onChange={(e) => handleChange(e)}
            >
              <option value="">Select Company</option>
              {sortedCompanies.map((c) => {
                return (
                  <option key={c.company_id} value={c.company_name}>
                    {c.company_name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="email_address"
              id="email_address_field"
              className="email-input"
              value={member.email_address}
              onChange={(e) => handleChange(e)}
              required
            />
            <label>Email address *</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="FNAME"
              value={member.FNAME}
              onChange={(e) => handleChange(e)}
            />
            <label>First name</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="LNAME"
              value={member.LNAME}
              onChange={(e) => handleChange(e)}
            />
            <label>Last name</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="PHONE"
              value={member.PHONE}
              onChange={(e) => handleChange(e)}
            />
            <label>Phone number</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="street_address"
              value={member.street_address}
              onChange={(e) => handleChange(e)}
            />
            <label>Street address</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="street_address2"
              value={member.street_address2}
              onChange={(e) => handleChange(e)}
            />
            <label>Street address 2</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="city"
              value={member.city}
              onChange={(e) => handleChange(e)}
            />
            <label>City</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="zipcode"
              value={member.zipcode}
              onChange={(e) => handleChange(e)}
            />
            <label>Postal/Zip</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="state"
              value={member.state}
              onChange={(e) => handleChange(e)}
            />
            <label>State/Prov/Region</label>
          </div>
          <div className="field">
            <select
              name="country"
              value={member.country}
              onChange={(e) => handleChange(e)}
            >
              {countries.map((c) => {
                return (
                  <option key={c.code} value={c.code}>
                    {c.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="group">
          {displayMemberTags()}
          <select
            id="tag_selector"
            name="tags"
            onChange={(e) => handleChange(e)}
            defaultValue="0"
          >
            <option value="0" disabled hidden>
              Select tags *
            </option>
            {sortedTags.map((t) => {
              return (
                <option key={t.id} value={t.id}>
                  {t.name}
                </option>
              );
            })}
          </select>
          <select
            name="status"
            value={member.status}
            onChange={(e) => handleChange(e)}
            required
          >
            <option value="subscribed">Subscribed</option>
            <option value="unsubscribed">Unsubscribed</option>
            <option value="cleaned">cleaned</option>
            <option value="pending">pending</option>
            <option value="transactional">transactional</option>
          </select>
        </div>
        <div className="group">
          <div className="selectorLabel">
            <p>Seller 1</p>
            <select
              id="seller_selector"
              name="SALES_ID"
              onChange={(e) => handleChange(e)}
              value={member.SALES_ID}
            >
              <option value="">Select Seller 1</option>
              {sortedSalesmen.map((s) => {
                return (
                  <option key={s.id} value={s.azure_object_id}>
                    {s.full_name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="selectorLabel">
            <p>Seller 2</p>
            <select
              id="seller_selector_two"
              name="S_ID_TWO"
              onChange={(e) => handleChange(e)}
              value={member.S_ID_TWO}
            >
              <option value="">Select Seller 2</option>
              {sortedSalesmen.map((s) => {
                return (
                  <option key={s.id} value={s.azure_object_id}>
                    {s.full_name}
                  </option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="group">
          <div className="field comment">
            <textarea
              type="text"
              name="COMMENT"
              value={member.COMMENT}
              onChange={(e) => handleChange(e)}
            />
            <label>Comment</label>
          </div>
        </div>
        <div className="group">
          <button type="submit">Add member</button>
        </div>
      </form>
    </div>
  );
};

const addStateToProps = (state) => ({
  tags: state.tags,
  members: state.members,
  salesmen: state.salesmen,
  companies: state.companies,
});

const addDispatchToProps = (dispatch) => ({
  postAndgetMembers: (member) => dispatch(postAndgetMembers(member)),
  addActivity: (activity) => dispatch(postActivity(activity)),
});

export default withRouter(
  connect(addStateToProps, addDispatchToProps)(AddMemberForm)
);
