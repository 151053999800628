import React from "react";
import { useLocation } from "react-router-dom";

/**
 * Removes comma, dot or space from string
 * @param {string} string Address
 * @param {string} extra comma, dot or space
 * @return {string} String without dot or comma
 */
export const removeDot = (string, extra) => {
  if (typeof string === "undefined") {
    return "";
  }
  if (string === "." || string === "," || string === "") {
    return "";
  } else if (string !== "." && string > 0 && extra) {
    // if string and extra exist
    return string + extra;
  } else if (
    // same as first but checks for a clean space in extra

    string !== "." &&
    typeof extra !== "undefined" &&
    extra.indexOf(" ") >= 0
  ) {
    return string + extra;
  } else if (
    // if no extra print just the string

    string !== "." &&
    string !== "None"
  ) {
    return string;
  } else {
    //empty function print nothing
    return "";
  }
};

/** Looks for email address in list and sets validity of fieldId
 *
 * @param {string} mail Mail address to find
 * @param {array} list List of objects that will be searched, objects must have key "email_address"
 * @param {string} target e.target of input
 * @param {string} exception Address to be skipped
 */
export const emailExists = (mail, list, target, exception) => {
  let input = target; //document.getElementById(fieldId);
  let exists = false;
  list.forEach((m) => {
    if (m.email_address === mail && m.email_address !== exception) {
      exists = true;
    }
  });
  if (exists) {
    input.setCustomValidity("This address already exists");
    input.reportValidity();
  } else {
    input.setCustomValidity("");
    input.reportValidity();
  }
};

// Code from Jquery, https://stackoverflow.com/questions/1038727/how-to-get-browser-width-using-javascript-code
/**
 * Returns true if screen is smaller then 767px.
 */
export const smallScreen = () => {
  function getWidth() {
    return Math.max(
      document.body.scrollWidth,
      document.documentElement.scrollWidth,
      document.body.offsetWidth,
      document.documentElement.offsetWidth,
      document.documentElement.clientWidth
    );
  }
  /* Might use later
  function getHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.documentElement.clientHeight
    );
  }
  */

  if (getWidth() <= 820) {
    return true;
  } else {
    return false;
  }
};

export const shortenLongString = (string, length) => {
  let message = string;
  if (message.length > length) {
    message = message.slice(0, length) + "...";
  }
  return message;
};

/** Requiers input to execute order
 *
 * @param {string} safeword password
 * @param {string} regarding The thing you will change
 * @param {string} extraText extra text message
 */
export const areYouSure = (safeword, regarding, extraText = "") => {
  const answer = window.prompt(
    "Are you sure?\n" +
      regarding +
      "\n" +
      extraText +
      "\nType '" +
      safeword +
      "' to continue."
  );
  if (answer === safeword) {
    return true;
  } else {
    return false;
  }
};

/** Sort array by object key with Number value
 *
 * @param {Array} list Array with objects
 * @param {string} keyId object key to sort after, the value must be a number or string with number
 */
export const sortArray = (list, keyId) => {
  if (typeof list !== "undefined") {
    let listToBeSorted = list;
    let listLength = listToBeSorted.length;
    for (let i = 0; i < listLength; i++) {
      for (let j = 0; j < listLength; j++) {
        if (listToBeSorted[j + 1]) {
          if (
            parseInt(listToBeSorted[j][keyId]) >
            parseInt(listToBeSorted[j + 1][keyId])
          ) {
            let tempObj = listToBeSorted[j + 1];
            listToBeSorted[j + 1] = listToBeSorted[j];
            listToBeSorted[j] = tempObj;
          }
        }
      }
    }
    return listToBeSorted;
  }
};

/** Sort array by object key with String value
 *
 * @param {Array} list Array with objects
 * @param {string} keyId object key to sort after, the value must be a String
 */
export const sortByString = (list, keyId) => {
  let sortedList = list.sort((a, b) => {
    const valueA = getObjectPathValue(keyId, a);
    const valueB = getObjectPathValue(keyId, b);
    if (valueA.toLowerCase() < valueB.toLowerCase()) {
      return -1;
    }
    if (valueA.toLowerCase() > valueB.toLowerCase()) {
      return 1;
    }
    return 0;
  });
  return sortedList;
};

/** Sort array by object key with number value, will convert string to int
 *
 * @param {Array} list Array with objects
 * @param {string} keyId object key to sort after, the value must be a String
 */
export const sortByNumber = (list, keyId) => {
  let sortedList = list.sort((a, b) => {
    let valueA = a[keyId];
    let ValueB = b[keyId];
    if (typeof valueA === "string") {
      valueA = parseInt(valueA);
    }
    if (typeof ValueB === "string") {
      ValueB = parseInt(ValueB);
    }

    if (valueA < ValueB) {
      //a[keyId] = a[keyId].toString();
      //b[keyId] = b[keyId].toString();
      return 1;
    }
    if (valueA > ValueB) {
      //a[keyId] = a[keyId].toString();
      //b[keyId] = b[keyId].toString();
      return -1;
    }
    return 0;
  });
  return sortedList;
};

/**
 * Takes object path as string, splits it and puts out the path value of the object.
 * @param {String} path
 * @param {Object} obj
 * @param {String} separator
 * @returns
 */
const getObjectPathValue = (path, obj, separator = ".") => {
  var properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => prev?.[curr], obj);
};

/** Sort array by object key with number/string, turning the value into date
 *  Result in sorted array
 *
 * @param {Array} list Array with objects
 * @param {string} keyId object key to sort after, the value must be a String
 */
export const sortByDate = (list, keyId) => {
  let sortedList = list.sort((a, b) => {
    //console.log(list, keyId);

    const parsedA = Date.parse(getObjectPathValue(keyId, a));
    const parsedB = Date.parse(getObjectPathValue(keyId, b));

    const date1 = new Date(parsedA);
    const date2 = new Date(parsedB);
    //console.log(date1, date2);
    if (!date1) {
      return 1;
    } else if (!date2) {
      return -1;
    }
    //Checks for invalid Date
    if (isNaN(date1)) {
      return 1;
    } else if (isNaN(date2)) {
      return -1;
    }

    if (date1 < date2) {
      return 1;
    }
    if (date1 > date2) {
      return -1;
    }
    return 0;
  });

  return sortedList;
};

export const sortByBoolean = (list, keyId) => {
  const sortedList = list.sort((a, b) => {
    let valueA = a[keyId];
    let ValueB = b[keyId];

    if (valueA < ValueB) {
      return 1;
    }
    if (valueA > ValueB) {
      return -1;
    }
    return 0;
  });
  return sortedList;
};

/**
 * Formats date to xxxx-xx-xx
 * @param {string} date String with date
 * @returns
 */
export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

/**
 * Formats date to xxxx-xx-xx
 * @param {string} date String with date
 * @returns
 */
export const formatDateTime = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear(),
    hour = d.getHours(),
    minute = d.getMinutes();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  const fullDate = [year, month, day].join("-");
  const fullTime = [
    hour > 9 ? hour : "0" + hour,
    minute > 9 ? minute : "0" + minute,
  ].join(":");
  return `${fullDate} ${fullTime}`;
};

/**
 * Decodes JWT tokens
 * @param {*} token
 * @returns json object
 */
export const parseJwt = (token) => {
  if (token == null) {
    return null;
  }
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

/**
 * Searches URL for given query "varname.get("query")"
 *
 */
export const useQuery = () => {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
};
