import React, { useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { postCompany } from "../../../redux/companies/actions";
import { postAndgetMembers } from "../../../redux/members/actions";
import { countries } from "../../countries";
import { sortArray, emailExists } from "../../tools";
import { useMsal } from "@azure/msal-react";

const AddCompanyForm = ({
  addCompany,
  postAndgetMembers,
  companies,
  history,
  tags,
  members,
}) => {
  const { accounts } = useMsal();
  let currentActiveMsUserName = accounts[0].name;

  const initCompany = {
    company_name: "",
    street: "",
    box: "",
    postal_code: "",
    state: "",
    city: "",
    tel1: "",
    mobile: "",
    email: "",
    country_code: "",
    company_id: "",
  };
  const [company, setCompany] = useState(initCompany);

  const [addAsMember, setAddAsMember] = useState(false);

  const initMember = {
    FNAME: "",
    LNAME: "",
    tags: [],
  };
  const [member, setMember] = useState(initMember);

  const createMemberObject = () => {
    return {
      email_address: company.email,
      status: "subscribed",
      merge_fields: {
        FNAME: member.FNAME,
        LNAME: member.LNAME,
        ADDRESS: {
          addr1: company.street.length > 0 ? company.street : ".",
          addr2: ".",
          city: company.city.length > 0 ? company.city : ".",
          state: company.state.length > 0 ? company.state : ".",
          zip: company.postal_code.length > 0 ? company.postal_code : ".",
          country: company.country_code,
        },
        PHONE: company.tel1,
        COMPANY: company.company_name,
        CREATED_BY: currentActiveMsUserName,
      },
      tags: member.tags,
    };
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;

    if (name === "tags") {
      setMember({ ...member, [name]: [value] });
    } else if (name === "email") {
      emailExists(value, members, e.target, "email_address_field");
      setCompany({ ...company, [name]: value });
    } else {
      setCompany({ ...company, [name]: value });
    }
  };

  const handleChangeMember = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    if (name === "tags") {
      setMember({ ...member, [name]: [value] });
    } else {
      setMember({ ...member, [name]: value });
    }
  };

  const getLastAddedCompanyId = () => {
    return parseInt(companies[companies.length - 1].company_id);
  };

  const handleChecked = () => {
    setAddAsMember(!addAsMember);
    if (addAsMember) {
      setMember(initMember);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const currentCompanyId = getLastAddedCompanyId();
    console.log(currentCompanyId); //Dubble check if ID is available
    const nextCompanyId = currentCompanyId + 1;
    company.company_id = nextCompanyId.toString();
    console.log(company);

    if (!addAsMember) {
      addCompany(company);
      history.push("/es/companies");
    } else {
      const memberObject = createMemberObject();

      addCompany(company);
      console.log(memberObject);
      postAndgetMembers(memberObject);
      history.push("/es/companies");
    }
  };

  const emailField = addAsMember ? (
    <div className="field">
      <input
        required
        type="text"
        name="email"
        id="email_address_field"
        value={company.email}
        onChange={(e) => handleChange(e)}
      />
      <label>Email*</label>
    </div>
  ) : (
    <div className="field">
      <input
        type="text"
        name="email"
        id="email_address_field"
        value={company.email}
        onChange={(e) => handleChange(e)}
      />
      <label>Email</label>
    </div>
  );

  const memberFields = (
    <div className="group">
      <div className="field">
        <input
          type="text"
          name="FNAME"
          value={member.FNAME}
          onChange={(e) => handleChangeMember(e)}
        />
        <label>First name</label>
      </div>
      <div className="field">
        <input
          type="text"
          name="LNAME"
          value={member.LNAME}
          onChange={(e) => handleChangeMember(e)}
        />
        <label>Last name</label>
      </div>
      <div className="field ">
        {addAsMember && (
          <select
            required
            id="tag_selector"
            name="tags"
            onChange={(e) => handleChangeMember(e)}
          >
            <option value="">Select tag *</option>
            {tags.map((t) => {
              return (
                <option key={t.id} value={t.name}>
                  {t.name}
                </option>
              );
            })}
          </select>
        )}
      </div>
    </div>
  );

  return (
    <div>
      <button className="back_button" onClick={() => history.goBack()}>
        Back
      </button>
      <form onSubmit={(e) => handleSubmit(e)}>
        <h3>Add company</h3>
        <div className="group">
          <div className="field">
            <input
              required
              type="text"
              id="company_name"
              name="company_name"
              value={company.company_name}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="company_name">Company name *</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="street"
              id="street"
              value={company.street}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="street">Street name</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="box"
              id="box"
              value={company.box}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="box">Box</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="state"
              id="state"
              value={company.state}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="state">State</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="postal_code"
              id="postal_code"
              value={company.postal_code}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="postal_code">Postal code</label>
          </div>
          <div className="field">
            <input
              type="text"
              name="city"
              id="city"
              value={company.city}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="city">City</label>
          </div>
          <div className="field">
            <select
              name="country_code"
              id="country_code"
              value={company.country_code}
              onChange={(e) => handleChange(e)}
              required
            >
              <option value="">Choose country</option>
              {countries.map((c) => {
                return (
                  <option key={c.code} value={c.code}>
                    {c.name}
                  </option>
                );
              })}
            </select>
            <label htmlFor="country_code">Country *</label>
          </div>
        </div>
        <div className="group">
          <div className="field">
            <input
              type="text"
              name="tel1"
              id="phone"
              value={company.tel1}
              onChange={(e) => handleChange(e)}
            />
            <label htmlFor="phone">Phone</label>
          </div>

          {emailField}
        </div>
        {addAsMember && memberFields}
        <div className="group">
          <div className="field ">
            <button type="submit">Add company</button>
          </div>
          <div className="field">
            <input
              type="checkbox"
              value={addAsMember}
              onChange={(e) => handleChecked(e)}
            />
            <label type="checkbox">Add company as member</label>
          </div>
        </div>
      </form>
    </div>
  );
};

const setStateToProps = (state) => ({
  companies: sortArray(state.companies, "company_id"),
  tags: state.tags,
  members: state.members,
});

const setDispatchToProps = (dispatch) => ({
  addCompany: (company) => dispatch(postCompany(company)),
  postAndgetMembers: (member) => dispatch(postAndgetMembers(member)),
});

export default withRouter(
  connect(setStateToProps, setDispatchToProps)(AddCompanyForm)
);
