import React, { useEffect, useState } from "react";

import { withRouter } from "react-router";
import { connect } from "react-redux";
import { getCountry } from "../countries";
import {
  removeDot,
  sortByString,
  sortArray,
  sortByNumber,
  sortByDate,
  sortByBoolean,
  smallScreen,
} from "../tools";
import { compose } from "redux";
import { getLastActivityObject } from "./getLastActivityObject";
import { memberlistDesktop } from "./memberlistDesktop";
import { memberlistMobile } from "./memberlistMobile";

const MemberList = ({ list, activities }) => {
  const [fixedList, setFixedList] = useState([]);
  const [sortedList, setSortedList] = useState([]);
  const [sortedKeyId, setSortedKeyId] = useState("");
  //const [editedList, setEditedList] = useState(list);
  const [transitionStyle, setTransitionStyle] = useState("fade-out");
  //console.log(list);
  //console.log(editedList);

  const sort = (e, keyId, type) => {
    e.preventDefault();

    if (keyId === sortedKeyId) {
      setSortedList(fixedList.reverse());
      setSortedKeyId("");
    } else {
      let listThatIsSorted = [];
      switch (type) {
        case "int":
          listThatIsSorted = sortByNumber(fixedList, keyId);
          break;
        case "array":
          listThatIsSorted = sortArray(fixedList, keyId);
          break;
        case "date":
          listThatIsSorted = sortByDate(fixedList, keyId);
          break;
        case "boolean":
          listThatIsSorted = sortByBoolean(fixedList, keyId);
          break;
        default:
          listThatIsSorted = sortByString(fixedList, keyId);
      }
      setSortedList([...listThatIsSorted]);
      setSortedKeyId(keyId);
    }
  };

  const View = () => {
    if (sortedList) {
      return smallScreen()
        ? memberlistMobile(fixedList)
        : memberlistDesktop(fixedList);
    }
  };

  useEffect(() => {
    const newList = list.map((m) => {
      let activity = getLastActivityObject(m.id, activities);

      return {
        ...m,
        merge_fields: {
          FNAME: removeDot(m.merge_fields.FNAME),
          LNAME: removeDot(m.merge_fields.LNAME),
          ADDRESS: {
            addr1: removeDot(m.merge_fields.ADDRESS.addr1, ", "),
            addr2: removeDot(m.merge_fields.ADDRESS.addr2, ", "),
            state: removeDot(m.merge_fields.ADDRESS.state, ", "),
            zip: removeDot(m.merge_fields.ADDRESS.zip, " "),
            city: removeDot(m.merge_fields.ADDRESS.city),
            country: getCountry(m.merge_fields.ADDRESS.country),
          },
          PHONE: m.merge_fields.PHONE,
          COMPANY: m.merge_fields.COMPANY,
          SALES_ID: m.merge_fields.SALES_ID,
          SALES_NAME: m.merge_fields.SALES_NAME,
          S_NAME_TWO: m.merge_fields.S_NAME_TWO,
          S_ID_TWO: m.merge_fields.S_ID_TWO,
          lastActivity: activity.createdAt || "",
          lastMessage: activity.message || "",
          fullName: `${m.merge_fields.FNAME} ${m.merge_fields.LNAME}`,
        },
      };
    });
    setTransitionStyle("fade-in");
    //console.log("contact", newList);
    setFixedList(newList);
  }, [list, activities]);

  //Update if sorted
  useEffect(() => {
    if (sortedList.length !== 0 && sortedList === fixedList) {
      setFixedList([...sortedList]);
    }
  }, [fixedList, sortedList]);

  return (
    <table className={"list " + transitionStyle} data-testid="member-list">
      <thead>
        <tr>
          <th>
            <button onClick={(e) => sort(e, "merge_fields.COMPANY")}>
              Company
            </button>
          </th>
          <th title="Sort on first name">
            <button onClick={(e) => sort(e, "merge_fields.FNAME")}>Name</button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "email_address")}>Email</button>
          </th>
          <th>Phone</th>
          <th>
            <button onClick={(e) => sort(e, "merge_fields.ADDRESS.addr1")}>
              Address
            </button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "merge_fields.ADDRESS.country")}>
              Country
            </button>
          </th>
          <th>
            <button onClick={(e) => sort(e, "status")}>Status</button>
          </th>
          <th>Tags</th>
          <th>
            <button onClick={(e) => sort(e, "merge_fields.SALES_NAME")}>
              Seller
            </button>
          </th>
          <th title="Last Comment">Comment</th>
          <th>
            <button
              onClick={(e) => sort(e, "merge_fields.lastActivity", "date")}
            >
              Last contact
            </button>
          </th>
          <th>Options</th>
        </tr>
      </thead>
      <View />
    </table>
  );
};

const addStateToProps = (state) => ({
  activities: state.contactActivities,
});

//port default connect(addStateToProps, null)(MemberList);

//export default withRouter(MemberList);
export default compose(withRouter, connect(addStateToProps, null))(MemberList);
