import React from "react";
import { Router, Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { useMsal } from "@azure/msal-react";
import Navigation from "./navigation";
import { closeMessage } from "./sendMessage";

import MailchimpApplicationPage from "./mailchimp/mailchimpApplicationPage";
import MembersPage from "./mailchimp/membersPage";
import ShowAllMembers from "./mailchimp/showAllMembers";
import EsPage from "./smtes/esPage";
import MakerPage from "./smtes/makers/makerPage";
import CompaniesPage from "./smtes/companies/companiesPage";
import CategoriesPage from "./smtes/categories/categoriesPage";
import MachinesPage from "./smtes/machines/machinesPage";
import { fetchEsIfNeeded } from "../redux/actions";

import { createBrowserHistory } from "history";
const history = createBrowserHistory();

const ApplicationPage = ({ fetchESCall, msToken }) => {
  history.listen((location) => {
    if (location.pathname.includes("/es")) {
      fetchESCall();
    }
  });

  const { accounts } = useMsal();
  let userName = accounts[0] ? accounts[0].name : "User";
  //console.log(accounts)
  return (
    <Router history={history}>
      <div className="App" data-testid="ApplicationPage">
        <Navigation />
        <div id="message">
          <button className="float-right cross" onClick={() => closeMessage()}>
            X
          </button>
          <span></span>
        </div>
        <Switch>
          <Route path="/es/makers">
            <MakerPage />
          </Route>
          <Route path="/es/categories">
            <CategoriesPage />
          </Route>
          <Route path="/es/machines">
            <MachinesPage />
          </Route>
          <Route path="/es/companies">
            <CompaniesPage />
          </Route>
          <Route path="/es">
            <EsPage />
          </Route>
          <Route path="/mailchimp/all-members">
            <ShowAllMembers />
          </Route>
          <Route path="/mailchimp">
            <MailchimpApplicationPage />
          </Route>
          <Route path="/">
            <h3 data-testid="welcome">Hello {userName}! &#128512;</h3>
            <MembersPage />
            <EsPage />
          </Route>
        </Switch>
      </div>
    </Router>
  );
};
//{msToken.account.name}! &#128512;
const mapStateToProps = (state) => ({
  msToken: state.msToken,
});

const mapDispatchToProps = (dispatch) => ({
  fetchESCall: () => dispatch(fetchEsIfNeeded()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationPage);
