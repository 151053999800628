import { sendMessage } from "./components/sendMessage";

const axios = require("axios");
// axios.defaults.headers.common["Authorization"] = "Basic " + process.env.REACT_APP_API_KEY;

const config = {
  headers: { Authorization: "Basic " + process.env.REACT_APP_API_KEY },
};

async function getMembers(offset, count) {
  const url = `/api/3.0/lists/${process.env.REACT_APP_API_AUDIENCE_ID}/members?fields=members.email_address,members.id,members.status,members.merge_fields,members.tags,members.list_id,members.unique_email_id,members.timestamp_opt,members.last_changed&offset=${offset}&count=${count}`;

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    displayError(error);
  }
}

async function getListStats() {
  const url = `/api/3.0/lists/${process.env.REACT_APP_API_AUDIENCE_ID}?fields=stats`;

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function searchMembers(term) {
  const url = "/api/3.0/search-members?query=" + term;

  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function postMember(member) {
  const url = `/api/3.0/lists/${process.env.REACT_APP_API_AUDIENCE_ID}/members`;

  try {
    console.log(member);
    const response = await axios.post(url, member, config);
    return response;
  } catch (error) {
    displayError(error);
    return error.response;
  }
}

async function putMember(member) {
  const url = `/api/3.0/lists/${process.env.REACT_APP_API_AUDIENCE_ID}/members/${member.id}`;

  try {
    const response = await axios.put(url, member, config);
    return response.data;
  } catch (error) {
    displayError(error);
  }
}

async function deleteMember(memberId) {
  const url = `/api/3.0/lists/${process.env.REACT_APP_API_AUDIENCE_ID}/members/${memberId}/actions/delete-permanent`;

  try {
    const response = await axios.post(url, config);
    return response.data;
  } catch (error) {
    displayError(error);
  }
}

async function getTags() {
  const url = `/api/3.0/lists/${process.env.REACT_APP_API_AUDIENCE_ID}/tag-search?offset=0&count=500 `;
  try {
    const response = await axios.get(url, config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

async function postTags(member) {
  const url = `/api/3.0/lists/${process.env.REACT_APP_API_AUDIENCE_ID}/members/${member.id}/tags`;
  try {
    const response = await axios.post(url, { tags: member.tags }, config);
    return response.data;
  } catch (error) {
    console.log(error);
  }
}

const displayError = (error) => {
  if (error.response.data.errors) {
    sendMessage(error.response.data.errors[0].message, "fail");
    console.log(error.response.data.errors[0].message);
  } else if (error.response.data.detail) {
    sendMessage(error.response.data.detail, "fail");
    console.log(error.response.data.detail);
  } else {
    sendMessage(error, "fail");
    console.log(error);
  }
};

export {
  getMembers,
  getListStats,
  searchMembers,
  postMember,
  putMember,
  deleteMember,
  getTags,
  postTags,
};
