export const tags = (state = [], action) => {
  switch (action.type) {
    case "ADD_TAG":
      for (let i = 0; i < state.length; i++) {
        if (state[i].id === action.payload.id) {
          return state;
        }
      }
      return [...state, { ...action.payload }];
    case "ADD_TAGS":
      return action.payload;

    default:
      return state;
  }
};
