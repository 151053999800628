import { connect } from "react-redux";
import { formatDateTime, smallScreen } from "../tools";
import { Link } from "react-router-dom";

const DisplaySellerActivity = ({ members, activities }) => {
  if (!activities) {
    return <p>Error loading activities</p>;
  }
  const sellerActivities = activities
    .slice(Math.max(activities.length - 10, 0))
    .reverse();

  if (sellerActivities.length === 0) {
    return <p>No activities available</p>;
  }

  const findContact = (id) => {
    return members.find((m) => m.id === id);
  };

  const mobileView = (contact, a, i) => (
    <tr key={i}>
      <td>
        <strong>{contact.merge_fields.COMPANY}</strong>
      </td>
      <td>
        Name: {contact.merge_fields.FNAME} {contact.merge_fields.LNAME}
      </td>
      <td>Seller: {a.sales_name}</td>
      <td className="narrow">Comment: {a.message}</td>
      <td>{formatDateTime(a.createdAt)}</td>
      <td className="memberCardButtons">
        <Link to={`/mailchimp/edit-member?id=${contact.id}`}>
          <button className="grow-1">View</button>
        </Link>
      </td>
    </tr>
  );

  const desktopView = (contact, a, i) => (
    <tr key={i}>
      <td>
        <strong>{contact.merge_fields.COMPANY}</strong>
      </td>
      <td>
        {contact.merge_fields.FNAME} {contact.merge_fields.LNAME}
      </td>
      <td>{a.sales_name}</td>
      <td className="narrow">{a.message}</td>
      <td>{formatDateTime(a.createdAt)}</td>
      <td>
        <Link to={`/mailchimp/edit-member?id=${contact.id}`}>View</Link>
      </td>
    </tr>
  );

  const list = sellerActivities.map((a, i) => {
    const contact = findContact(a.contact_id);
    if (contact) {
      return smallScreen()
        ? mobileView(contact, a, i)
        : desktopView(contact, a, i);
    }
    return (
      <tr key={i}>
        <td>Incoming activity</td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
    );
  });

  return (
    <table className={"list "}>
      <thead>
        <tr>
          <th>Company</th>
          <th>Name</th>
          <th>Who</th>
          <th className="narrow">Message</th>
          <th>Date</th>
          <th>Options</th>
        </tr>
      </thead>
      <tbody>{list}</tbody>
    </table>
  );
};

const addStateToProps = (state) => ({
  members: state.members,
  activities: state.contactActivities,
});

export default connect(addStateToProps, null)(DisplaySellerActivity);
