import {
  getSalesmen,
  getLastUpdated,
} from "../../apiES";
import { sortByDate } from "../../components/tools";
import { countAndCompareType } from "../countAndCompareType";

const addSalesmen = (salesmen) => ({
  type: "ADD_SALESMEN",
  payload: salesmen,
});

const fetchAllSalesmen = () => {
  return (dispatch) => {
    getSalesmen().then((data) => {
      if (typeof data !== "undefined") {
        console.log(data)
        dispatch(addSalesmen(data));
      }
    });
  };
};

const updateSalesmen = (salesmen) => ({
  type: "UPDATE_SALESMEN",
  payload: salesmen,
});

const fetchUpdatedSalesmen = () => {
  return (dispatch, getState) => {
    const salesmen = [...getState().salesmen];
    const latestUpdatedSalesmen = sortByDate([...salesmen], "updatedAt");
    getLastUpdated("workforce", latestUpdatedSalesmen[0].updatedAt).then((data) => {
      if (typeof data !== "undefined" && data.length !== 0) {
        dispatch(updateSalesmen(data));
      }
    });
  };
};

//Primary function
export const fetchSalesmen = () => { 
  return async (dispatch, getState) => {
    const salesmenLength = getState().salesmen.length;
    
    const inSyncWithApi = await countAndCompareType("workforce", salesmenLength);
    
    if (salesmenLength > 0 && inSyncWithApi) {
      
      dispatch(fetchUpdatedSalesmen());
    } else {
      
      dispatch(fetchAllSalesmen());
    }
  };
};

