import React from "react";
import { formatDate, shortenLongString } from "../tools";
import { Link } from "react-router-dom";

import { displayTags } from "./displayTags";

export const memberlistDesktop = (fixedList) => (
  <tbody>
    {fixedList.map((m, i) => {
      return (
        <tr className="member" key={i} data-testid="member">
          <td className="company_name">
            <strong data-testid="member_company_name">
              {m.merge_fields.COMPANY}
            </strong>
          </td>
          <td className="name">
            {m.merge_fields.FNAME + " " + m.merge_fields.LNAME}
          </td>
          <td className="email">
            <a href={"mailto:" + m.email_address} data-testid="member_email">
              {m.email_address}
            </a>
          </td>

          <td className="phone">
            <a href={"tel:" + m.merge_fields.PHONE}>{m.merge_fields.PHONE}</a>
          </td>
          <td className="address">
            {m.merge_fields.ADDRESS.addr1}
            {m.merge_fields.ADDRESS.addr2}
            {m.merge_fields.ADDRESS.state}
            {m.merge_fields.ADDRESS.zip}
            {m.merge_fields.ADDRESS.city}
          </td>
          <td className="country">{m.merge_fields.ADDRESS.country}</td>

          <td className="status">{m.status}</td>
          <td className="tags">{displayTags(m.tags)}</td>
          <td className="seller">
            {m.merge_fields.SALES_NAME}
            {m.merge_fields.S_NAME_TWO && `, ${m.merge_fields.S_NAME_TWO}`}
          </td>
          <td className="comment">
            {shortenLongString(m.merge_fields.lastMessage, 100)}
          </td>
          <td className="lastchanged">
            {m.merge_fields.lastActivity &&
              formatDate(m.merge_fields.lastActivity)}
          </td>

          <td>
            <Link to={`/mailchimp/edit-member?id=${m.id}`}>Edit</Link>
          </td>
        </tr>
      );
    })}
  </tbody>
);
