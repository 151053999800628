import { flatState } from "../flatState";

export const salesmen = (state = [], action) => {
  switch (action.type) {
    case "ADD_SALESMEN":
      console.log(action.payload)
      return flatState(action.payload);
    case "UPDATE_SALESMEN":
      const payloadIds = new Set(action.payload.map((salesmen) => salesmen.id));
      const filteredState = state.filter((salesmen) => !payloadIds.has(salesmen.id)); //Filters duplicates
      const flatPayload = flatState(action.payload);
      const updatedState = [...filteredState, ...flatPayload]; //Add what  the filter removed
      return updatedState;
    default:
      return state;
  }
};
