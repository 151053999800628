/**
 *
 * @param {String} contactId, Mailchimp contact ID
 * @param {Array} activities, array of ES Activites
 * @returns Object with createdAt and message.
 */
export const getLastActivityObject = (contactId, activities) => {
  if (!activities) {
    return "";
  }
  const contactActivities = activities.filter(
    (a) => a.contact_id === contactId
  );
  //console.log(activities, contactId);
  if (contactActivities.length !== 0) {
    const lastAcivity = contactActivities.pop(); //Get last actvity
    return lastAcivity;
  } else {
    return {
      createdAt: "",
      message: "",
    };
  }
};
